import moment from '~/moment'
import _ from 'underscore'

export default {
  props: {
    alert: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      currentReading: '-',
    }
  },
  mounted() {
    this.loadReading()
  },
  computed: {
    alertDuration() {
      let duration

      if (this.alert.returned_to_normal_at) {
        duration = Date.parse(this.alert.returned_to_normal_at) - Date.parse(this.alert.entered_at)
      } else {
        duration = (new Date()).getTime() - Date.parse(this.alert.entered_at)
      }

      return moment.duration(duration).humanize()
    },
    alertIcon() {
      return this.alert.excursion_at ? 'error' : 'warning'
    },
    comments() {
      return _.filter(this.alert.comments, (comment) => comment.text != 'Alarm acknowledged')
    },
    triggeredAt() {
      return moment(this.alert.entered_at).format(momentDateTimeFormat)
    },
    excursionAt() {
      return this.alert.excursion_at ? moment(this.alert.excursion_at).format(momentDateTimeFormat) : '-'
    },
    resolvedAt() {
      return this.alert.returned_to_normal_at ? moment(this.alert.returned_to_normal_at).format(momentDateTimeFormat) : '-'
    },
    iconClass() {
      return this.alert.excursion_at ? 'text-danger' : 'text-warning'
    },
    lastReading() {
      if (this.alert?.channel?.timestamp) {
        return this.alert.channel.timestamp * 1000
      } else {
        return null
      }
    },
  },
  methods: {
    loadReading() {
      if (this.loaded || !this.lastReading) {
        return
      }

      const requester = this.requester
      const timestamp = this.lastReading

      this.loaded = true

      requester.fetch(timestamp, timestamp, 1, (channel) => {
        this.currentReading = channel.lastDatapoint()?.format()
      }).catch(() => this.loaded = false)
    },
    clearReasonCode() {
      this.alert.alert_note.reason_code_id = null
    },
    updateReasonCode(reasonCode) {
      this.alert.alert_note.reason_code_id = reasonCode
    },
  },
}
