import _ from 'underscore'
import moment from '~/moment'

import equipmentIcon from '../../helpers/equipment_icon'
import ChannelSection from './channel_section'
import PillLink from '../shared/pill_link'

function timeDistance(timestamp) {
  return timestamp ? moment(timestamp).fromNow() : '-'
}

const EquipmentCard = {
  name: 'equipment-card',
  template: '#equipment-card',
  components: {ChannelSection, PillLink},
  props: {
    equipment: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      channels: this.equipment.channels,
      device: this.equipment.channels[0]?.device,
      id: this.equipment.id,
      location: this.equipment.location,
      name: this.equipment.name,
      path: this.equipment.path,
      type: this.equipment.localized_type,
      icon: equipmentIcon(this.equipment),
      timestamp: timeDistance(this.equipment.timestamp),
    }
  },
  mounted() {
    setInterval(this.refreshTimestamp, 1000)
  },
  methods: {
    extraDeviceCount() {
      const totalDeviceTokens = _.chain(this.channels).pluck('token').uniq().value().length
      return totalDeviceTokens > 1 ? `+${totalDeviceTokens - 1}` : ''
    },
    refreshTimestamp() {
      this.timestamp = timeDistance(this.equipment.timestamp)
    },
  },
}

export default EquipmentCard
