import Multiselect from 'vue-multiselect'

const MultiselectFilter = {
  components: {Multiselect},
  props: {
    label: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      required: false,
      default: true,
    },
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    modelValue: {
      type: [Array, String, Object],
    },
    trackBy: {
      type: String,
      default: '',
    },
  },
  template: `
    <div class="filter-form-group">
      <label class="form-label">{{label}}</label>
      <multiselect :model-value="modelValue" @update:model-value="$emit('update:modelValue', $event)" :options="options" :custom-label="displayOption" :multiple="multiple" :placeholder="placeholder" :track-by="trackBy" />
    </div>`,
  methods: {
    displayOption(option) {
      if (typeof option === 'string') {
        return option
      }

      return option.label
    },
  },
}

export default MultiselectFilter
